import { Container } from "./footer.styles";

const Footer = () => {
  return (
    <Container>
      <h5>&#169; TATITAJA</h5>
      <p>Mikael Kolehmainen</p>
      <p>Vamia</p>
    </Container>
  );
};

export default Footer;
