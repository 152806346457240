import styled from "styled-components";

export const Container = styled.div`
  width: 80%;
  margin: 75px auto 170px auto;

  h1 {
    text-align: left;
    margin-bottom: 50px;
  }
  p {
    margin: 5px 0;
  }
  .btn {
    position: relative;
    padding: 20px 20px 20px 40px;
    margin: 100px 0 0 0;
  }
  i {
    display: block;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translate(-50%, -50%);
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const ShoppingCart = styled.div`
  width: 70%;
  height: 290px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 25px;
  position: relative;
`;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: auto;
  height: 82.5%;
`;

export const Item = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 10px;

  .icon {
    margin: 0;
    width: 35px;
    height: 35px;

    &:hover {
      cursor: pointer;
    }
  }
  .icon i {
    top: 50%;
    left: 50%;
  }
  input {
    width: 45px;
    padding: 0 5px 0 10px;
    font-size: 14px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #b0b0b0;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    opacity: 1;
  }
`;

export const Information = styled.div`
  margin: 0 auto 0 15px;

  h5 {
    font-size: 18px;
  }
  p {
    font-style: italic;
    font-size: 14px;
  }
`;

export const Price = styled.p`
  font-size: 20px;
  font-weight: bold;
  height: fit-content;
  width: 75px;
  margin: 12px 0 !important;
  text-align: right;
`;

export const Bottom = styled.div`
  display: flex;
  bottom: 20px;
  position: absolute;
  height: 50px;
  width: 95%;

  .btn {
    background-color: transparent;
    margin: auto auto auto 0;
    padding: 0 0 0 40px;
  }
`;

export const Total = styled.div`
  display: flex;
  margin: 0 0 0 auto;

  p {
    height: fit-content;
    margin: auto;
    font-size: 18px;
  }
  p:last-child {
    margin-left: 40px;
    font-weight: bold;
  }
`;

export const OrderForm = styled.form`
  background-color: #c4c4c4;
  border-radius: 5px;
  width: 30%;
  padding: 25px 0;

  input {
    display: block;
    width: 80%;
    margin: 0 auto 25px auto;
    height: 45px;
    padding: 0 20px;
    border-radius: 5px;
    border: 1px solid #b0b0b0;
    outline: none;
    font-size: 14px;
  }
`;

export const SubmitButton = styled.button`
  display: block;
  width: 89%;
  margin: 0 auto 25px auto;
  height: 50px;
  padding: 0 20px;
  font-size: 16px;
  display: block;
  position: relative;

  &:hover {
    cursor: pointer;
  }
  i {
    left: 65%;
    top: 51%;
    width: 18px;
    height: 18px;
  }
`;
