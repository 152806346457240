import { createSlice } from "@reduxjs/toolkit";

/*
  This is cartSlice.js. This is a slice, where we can define different functions
  for the state, for example for storing a value to the state or clearing that
  specific value. We then export these functions so that they can be passed to
  the rootReducer.
*/

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    example: null,
  },
  reducers: {
    saveCart: (state, action) => {
      state.cart = action.payload;
    },
    clearCart: (state) => {
      state.cart = null;
    },
  },
});

export const { saveCart, clearCart } = cartSlice.actions;

export default cartSlice.reducer;
