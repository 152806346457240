import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  border: 1px solid ${(props) => props.theme.primaryColor};
  width: fit-content;
  padding: 10px 20px;
  border-radius: 100vmax;
  margin-bottom: 40px;

  input {
    border: none;
    font-size: 18px;
    margin-left: 15px;
    width: 325px;

    &:active,
    &:focus {
      border: none;
      outline: none;
    }
  }
  .icon {
    background-color: transparent;
    width: 20px;
    height: 20px;
  }
  .icon i {
    width: 100%;
    height: 100%;
  }
`;
