import { styled } from "styled-components";

/*
  This is the styles for the Home page. I use a package called 'styled-components'
  which lets you define HTML elements and directly write CSS for them.
*/

export const Container = styled.section`
  margin: 50px auto;
  width: 75%;

  h1 {
    margin-bottom: 30px;
  }
`;

export const Hero = styled.article`
  background-image: url(/media/hero.jpeg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  text-align: center;
  height: 450px;
  padding-top: 150px;

  h1 {
    font-size: 50px;
    color: ${(props) => props.theme.primaryColor};
    width: 40%;
    margin: auto;
  }
  p {
    color: ${(props) => props.theme.primaryColor};
    margin-top: 20px;
  }
  a {
    margin-top: 50px;
  }
`;

export const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Item = styled.div`
  width: 29%;
  text-align: center;
  margin-top: 20px;
  margin: 20px 2%;

  h2 {
    margin: 20px 0;
  }
  p {
    width: 70%;
    margin: auto;
    line-height: 1.5;
  }
`;
