/*
  Here I define the colors, default font sizes, etc. for the application.
  This theme object will be passed to a ThemeProvider in App.tsx, which means
  that I can access these values in styled components through the props object.
*/

export const theme = {
  primaryColor: "#002809",
  secondaryColor: "#98CA4A",
  primaryFontColor: "#B9C56C",
  secondaryFontColor: "white",
  miscColor: "#C3AA79",
};
