import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchBar from "../../components/searchBar/searchBar.component";
import { BuyButton, Container, Filter, Filters, Price, Product, ProductBottom, Products } from "./shop.styles";
import Header from "../../components/header/header.component";
import Footer from "../../components/footer/footer.component";
import { GetCategories, GetProducts } from "../../backend/backend";
import { saveCart } from "../../store/example/cartSlice";

const Shop = () => {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [fetchFailed, setFetchFailed] = useState(false);
  const [filteredCategories, setFilteredCategories] = useState([1, 2, 3, 4, 5, 6]);
  const [search, setSearch] = useState("");
  const cart = useSelector((state) => state.shop.cart);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await GetCategories();
        setCategories(response.data);
      } catch (error) {
        console.error(error);
        setFetchFailed(true);
      }
    };

    const fetchProducts = async () => {
      try {
        const response = await GetProducts();
        setProducts(response.data);
      } catch (error) {
        console.error(error);
        setFetchFailed(true);
      }
    };

    fetchCategories();
    fetchProducts();
  }, []);

  const filterByCategory = (event, categoryId) => {
    let dtoFilteredCategories = [...filteredCategories];

    if (event.target.checked) {
      // Save category id
      dtoFilteredCategories.push(categoryId);
      setFilteredCategories(dtoFilteredCategories);
    } else {
      // Delete category id
      const indexOfCategory = dtoFilteredCategories.indexOf(categoryId);
      if (indexOfCategory !== -1) {
        dtoFilteredCategories.splice(indexOfCategory, 1);
      }
      setFilteredCategories(dtoFilteredCategories);
    }

    const dtoProducts = [...products];

    if (categoryId === "all" && event.target.checked) {
      dtoFilteredCategories = [1, 2, 3, 4, 5, 6];
      setFilteredCategories(dtoFilteredCategories);
    }

    dtoProducts.forEach((product) => {
      if (dtoFilteredCategories.indexOf(product.category_id) === -1) {
        product.filtered = true;
      } else {
        product.filtered = false;
      }
    });

    setProducts(dtoProducts);
  };

  const searchProduct = (event) => {
    let searchValue = event.target.value;
    setSearch(searchValue);
    searchValue = searchValue.toLowerCase();

    const dtoProducts = [...products];
    dtoProducts.forEach((product) => {
      if (filteredCategories.indexOf(product.category_id) !== -1) {
        const productName = product.name.toLowerCase();
        if (productName.includes(searchValue)) {
          product.filtered = false;
        } else {
          product.filtered = true;
        }
      }
    });

    setProducts(dtoProducts);
  };

  const addProductToCart = (product) => {
    // deep copy of object
    let dtoCart = JSON.parse(JSON.stringify(cart));
    let itemAlreadyExists = false;
    dtoCart.items.forEach((item) => {
      if (item.id === product.id) {
        item.amount += 1;
        itemAlreadyExists = true;
      }
    });
    if (itemAlreadyExists) {
      dispatch(saveCart(dtoCart));
      return;
    }

    product.amount = 1;
    dtoCart.items.push(product);
    dispatch(saveCart(dtoCart));
  };

  if (fetchFailed) {
    return <p>Hups, jokin meni pieleen.</p>;
  }

  return (
    <>
      <Header isShop={true} />
      <Container>
        <h1>Verkkokauppa</h1>
        <SearchBar placeholder="Esti tuotteita" value={search} onChange={searchProduct} />
        <Filter>
          <input
            type="checkbox"
            id="all"
            checked={categories.length === filteredCategories.length}
            onChange={(e) => filterByCategory(e, "all")}
          />
          <label htmlFor="all">Kaikki</label>
        </Filter>
        <Filters>
          {categories.map((category) => {
            return (
              <Filter key={category.id}>
                <input
                  type="checkbox"
                  id={`cateogry-${category.id}`}
                  onChange={(e) => filterByCategory(e, category.id)}
                  checked={filteredCategories.indexOf(category.id) !== -1}
                />
                <label htmlFor={`cateogry-${category.id}`}>{category.name}</label>
              </Filter>
            );
          })}
        </Filters>
        <h3>Yhteensä {products.filter((product) => !product.filtered).length} tuottetta</h3>
        <Products>
          {products.map((product) => {
            return product.filtered ? (
              <></>
            ) : (
              <Product key={product.id}>
                <h3>{product.name}</h3>
                <h4>{product.category_name}</h4>
                <p>{product.description}</p>
                <ProductBottom>
                  <Price>
                    <p>{product.prize} €</p>
                  </Price>
                  <BuyButton onClick={() => addProductToCart(product)}>
                    <p>Osta</p>
                  </BuyButton>
                </ProductBottom>
              </Product>
            );
          })}
        </Products>
      </Container>
      <Footer />
    </>
  );
};

export default Shop;
