import { Link } from "react-router-dom";
import Header from "../../components/header/header.component";
import {
  Bottom,
  Container,
  FlexContainer,
  Information,
  Item,
  Items,
  OrderForm,
  Price,
  ShoppingCart,
  SubmitButton,
  Total,
} from "./cart.styles";
import { StyledIcon } from "../../components/icon/icon.styles";
import Footer from "../../components/footer/footer.component";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../components/icon/icon.component";
import { clearCart, saveCart } from "../../store/example/cartSlice";
import { useState } from "react";
import { saveOrderDetails } from "../../backend/backend";

const Cart = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.shop.cart);
  const [personalDetails, setPersonalDetails] = useState({ name: "", email: "", phone: "" });
  const [isOrdered, setIsOrdered] = useState(false);

  const getTotalAmount = () => {
    let total = 0;
    cart.items.forEach((item) => {
      total += item.amount * item.prize;
    });
    return total.toFixed(2);
  };

  const updateItemAmount = (event, item, index) => {
    const dtoItem = { ...item };
    dtoItem.amount = parseInt(event.target.value);
    const dtoCart = JSON.parse(JSON.stringify(cart));
    if (dtoItem.amount <= 0) {
      dtoCart.items.splice(index, 1);
    } else {
      dtoCart.items[index] = dtoItem;
    }
    dispatch(saveCart(dtoCart));
  };

  const removeItem = (index) => {
    const dtoCart = { ...cart };
    dtoCart.items.splice(index, 1);
    dispatch(saveCart(dtoCart));
  };

  const submitOrder = async (event) => {
    event.preventDefault();
    if (!personalDetails.name || !personalDetails.email || !personalDetails.phone) {
      return;
    }

    const orderDetails = { ...cart, personalDetails };
    await saveOrderDetails(orderDetails);
    dispatch(clearCart());
    setIsOrdered(true);
  };

  if (isOrdered) {
    return (
      <>
        <Header isShop={true} />
        <Container>
          <h1>Kiitos tilauksesta!</h1>
          <Link to="/shop" className="btn">
            Tee uusi tilaus
          </Link>
        </Container>
        <Footer />
      </>
    );
  }

  if (!cart || cart.items.length <= 0) {
    return (
      <>
        <Header isShop={true} />
        <Container>
          <h1>Ostoskori</h1>
          <p>Pahoittelemme, mutta ostoskorisi on tällä hetkellä tyhjä.</p>
          <p>Jatka ostosten tekemistä lisätäksesi tuotteita ostoskoriisi</p>
          <Link to="/shop" className="btn with-icon">
            <StyledIcon iconPath={"/media/icons/angles-left-solid.svg"} />
            Selaa ilmoituksia
          </Link>
        </Container>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header isShop={true} />
      <Container>
        <h1>Ostoskori</h1>
        <FlexContainer>
          <ShoppingCart>
            <Items>
              {cart.items.map((item, i) => {
                return (
                  <Item key={i}>
                    <Icon iconPath={"/media/icons/trash-can-solid.svg"} onClick={() => removeItem(i)} />
                    <Information>
                      <h5>{item.name}</h5>
                      <p>{item.category_name}</p>
                    </Information>
                    <input type="number" value={item.amount} onChange={(e) => updateItemAmount(e, item, i)} />
                    <Price>{item.prize}€</Price>
                  </Item>
                );
              })}
            </Items>
            <Bottom>
              <Link to="/shop" className="btn with-icon">
                <StyledIcon iconPath={"/media/icons/angles-left-solid.svg"} />
                Takaisin kauppaan
              </Link>
              <Total>
                <p>Yhteensä</p>
                <p>{getTotalAmount()}€</p>
              </Total>
            </Bottom>
          </ShoppingCart>
          <OrderForm>
            <input
              type="text"
              placeholder="Nimi"
              value={personalDetails.name}
              onChange={(event) => setPersonalDetails({ ...personalDetails, name: event.target.value })}
            />
            <input
              type="email"
              placeholder="Sähköpostiosoite"
              value={personalDetails.email}
              onChange={(event) => setPersonalDetails({ ...personalDetails, email: event.target.value })}
            />
            <input
              type="tel"
              placeholder="Puhelinnumero"
              value={personalDetails.phone}
              onChange={(event) => setPersonalDetails({ ...personalDetails, phone: event.target.value })}
            />

            <SubmitButton onClick={async (e) => await submitOrder(e)}>
              Tee tilaus <StyledIcon iconPath={"/media/icons/angles-right-solid.svg"} />
            </SubmitButton>
          </OrderForm>
        </FlexContainer>
      </Container>
      <Footer />
    </>
  );
};

export default Cart;
