import styled from "styled-components";

export const Container = styled.section`
  width: 80%;
  margin: 50px auto;

  label {
    color: ${(props) => props.theme.primaryColor};
  }
  h1 {
    text-align: left;
    margin-bottom: 40px;
  }
  h3 {
    text-align: right;
    margin: 25px 0;
  }
`;

export const Filters = styled.div`
  display: flex;
  gap: 20px;
  margin: 20px 0;
`;

export const Filter = styled.div`
  label {
    font-size: 18px;
    margin-left: 5px;
  }
  input {
    width: 20px;
    height: 20px;
    box-sizing: border-box;
  }
`;

export const Products = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const Product = styled.div`
  width: 29%;
  margin: 10px 1%;
  border: 1px solid ${(props) => props.theme.primaryColor};
  border-radius: 10px;
  padding: 15px 1%;
  min-height: 200px;
  position: relative;

  h3 {
    color: ${(props) => props.theme.primaryColor};
    font-size: 22px;
    text-align: left;
    margin: 0;
  }
  h4 {
    font-style: italic;
    margin: 10px 0 30px 0;
    font-weight: normal;
    font-size: 18px;
  }
`;

export const ProductBottom = styled.div`
  display: flex;
  position: absolute;
  bottom: 10px;
  width: 94%;
`;

export const Price = styled.div`
  p {
    font-size: 26px;
    font-weight: bold;
  }
`;

export const BuyButton = styled.button`
  padding: 10px 25px;
  height: fit-content;
  width: fit-content;
  border-radius: 7px;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  margin: auto;
  display: block;
  margin: 0 0 0 auto;

  &:hover {
    cursor: pointer;
  }
`;
