import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Container, Nav } from "./header.styles";
import { StyledIcon } from "../icon/icon.styles";
import Icon from "../icon/icon.component";
import { saveCart } from "../../store/example/cartSlice";

const Header = ({ isShop }) => {
  const dispatch = useDispatch();

  const createSession = () => {
    let sessionKey = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 30; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      sessionKey += characters.charAt(randomIndex);
    }

    dispatch(saveCart({ session: sessionKey, items: [] }));
  };

  return isShop ? (
    <Container>
      <Link to="/">
        <img src="/media/alt_logo.png" alt="Tuottajamarket logo" />
      </Link>
      <Nav>
        <Link to="/cart">
          {/** TODO: also display amount of items in cart */}
          <Icon iconPath={"/media/icons/cart-shopping-solid.svg"} />
        </Link>
        <Link to="/">Etusivu</Link>
      </Nav>
    </Container>
  ) : (
    <Container>
      <Link to="/">
        <img src="/media/alt_logo.png" alt="Tuottajamarket logo" />
      </Link>
      <Nav>
        <Link to="/shop" onClick={createSession} className="btn with-icon">
          Kauppaan <StyledIcon iconPath={"/media/icons/angles-right-solid.svg"} />
        </Link>
      </Nav>
    </Container>
  );
};

export default Header;
