import axios from "axios";

/*
  This is backend.ts. Here are the functions that call endpoints on the backend,
  mostly for fetching data from the database throught the backend controllers.
*/

export const GetCategories = async () => {
  return axios.get(`${process.env.REACT_APP_BACKEND_URI}/category/get-all`);
};

export const GetProducts = async () => {
  return axios.get(`${process.env.REACT_APP_BACKEND_URI}/product/get-all`);
};

export const saveOrderDetails = async (orderDetails) => {
  return axios.post(`${process.env.REACT_APP_BACKEND_URI}/order/store`, orderDetails, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};
