import styled from "styled-components";

export const Container = styled.header`
  background-color: white;
  display: flex;

  img {
    width: 200px;
  }
  a {
    height: fit-content;
    width: fit-content;
    text-align: center;
    font-weight: bold;
    text-decoration: none;
    margin: auto auto auto 30px;
    display: block;
    color: ${(props) => props.theme.primaryColor};
  }
  .icon {
    width: 28px;
    height: 28px;
    border-radius: 0;
    background-color: transparent;
    margin: auto 20px;
  }
  .icon i {
    width: 100%;
    height: 100%;
  }
  i {
    width: 15px;
    height: 15px;
    display: block;
    position: absolute;
    top: 50%;
    left: 82.5%;
    transform: translate(-50%, -50%);
  }
  .btn.with-icon {
    position: relative;
    padding-right: 45px;
  }
`;

export const Nav = styled.nav`
  display: flex;
  margin: 0 10% 0 auto;
`;
