import React, { useEffect, useState } from "react";
import { Container, Hero, Item, Items } from "./home.styles";
import { Link } from "react-router-dom";
import Header from "../../components/header/header.component";
import Icon from "../../components/icon/icon.component";
import Footer from "../../components/footer/footer.component";

/*
  This is the Home (or the Main) page. This component will always be the landing
  page and is defined as the landing page in App.tsx, the route is '/'.
*/

const Home = () => {
  return (
    <>
      <Header isShop={false} />
      <section>
        <Hero>
          <h1>Tuoretta lähiruokaa suoraan kotiovellesi!</h1>
          <p>Tuottajamarket - Kaupunkilaisille vaivatonta lähiruoan hankintaa.</p>
          <Link to="/shop" className="btn cta">
            Selaa tuotteita
          </Link>
        </Hero>
        <Container>
          <h1>Kuinka Se Toimii</h1>
          <Items>
            <Item>
              <Icon iconPath={"/media/icons/pen-solid.svg"} />
              <div className="content">
                <h2>1. Rekisteröityminen</h2>
                <p>
                  Aloita rekisteröitymällä Tuottajamarket-verkkopalveluun omalla käyttäjätunnuksellasi ja salasanallasi.
                </p>
              </div>
            </Item>
            <Item>
              <Icon iconPath={"/media/icons/magnifying-glass-solid.svg"} />
              <div className="content">
                <h2>2. Selaa tuotteita</h2>
                <p>
                  Kirjauduttuasi sisään voit selata monipuolista valikoimaa laadukkaita ja lähellä tuotettuja tuotteita.
                  Käy läpi eri tuotekategorioita ja tutustu tarjontaan.
                </p>
              </div>
            </Item>
            <Item>
              <Icon iconPath={"/media/icons/circle-plus-solid.svg"} />
              <div className="content">
                <h2>3. Lisää ostoskoriin</h2>
                <p>
                  Valittuasi haluamasi tuotteet voit lisätä ne ostoskoriin. Voit valita useita tuotteita eri
                  kategorioista.
                </p>
              </div>
            </Item>
            <Item>
              <Icon iconPath={"/media/icons/face-smile-regular.svg"} />
              <div className="content">
                <h2>4. Tarkista ostokset</h2>
                <p>
                  Siirry ostoskoriin ja tarkista valintasi ennen tilauksen vahvistamista. Voit muuttaa määriä tai
                  poistaa tuotteita tarvittaessa.
                </p>
              </div>
            </Item>
            <Item>
              <Icon iconPath={"/media/icons/thumbs-up-regular.svg"} />
              <div className="content">
                <h2>5. Tilauksen vahvistus</h2>
                <p>Kun olet tyytyväinen ostoskoriisi, vahvista tilauksesi ja syötä tarvittavat toimitustiedot</p>
              </div>
            </Item>
            <Item>
              <Icon iconPath={"/media/icons/gift-solid.svg"} />
              <div className="content">
                <h2>6. Toimitus kotiovelle</h2>
                <p>
                  Odota tuotteiden saapumista kotiovellesi. Tuottajamarket-verkkopalvelu toimittaa laadukkaat
                  lähiruokatuotteet vaivattomasti sinulle.
                </p>
              </div>
            </Item>
            <Item>
              <Icon iconPath={"/media/icons/heart-regular.svg"} />
              <div className="content">
                <h2>7. Nauti lähiruoasta</h2>
                <p>
                  Saapuneet tuotteet ovat nyt valmiita nautittavaksi. Nauti herkullisista ja laadukkaista lähiruoista
                  omassa arjessasi!
                </p>
              </div>
            </Item>
          </Items>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default Home;
