import styled from "styled-components";

export const Container = styled.footer`
  background-color: ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.secondaryFontColor};
  text-align: center;
  padding: 60px 0;

  h5 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  p {
    font-weight: normal;
  }
`;
