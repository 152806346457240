import Icon from "../icon/icon.component";
import { Container } from "./searchBar.styles";

const SearchBar = ({ placeholder, value, onChange }) => {
  return (
    <Container>
      <Icon iconPath={"/media/icons/magnifying-glass-solid.svg"} />
      <input type="text" placeholder={placeholder} value={value} onChange={onChange} />
    </Container>
  );
};

export default SearchBar;
